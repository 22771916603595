
import { useRouter } from "vue-router";
import { defineComponent } from "vue";

export default defineComponent({
  name: "PageTitle",
  props: {
    breadcrumbs: {
      type: Array,
    },
    heading: {},
    subheading: {},
    separator: {
      type: String,
      default: ">",
      required: false,
    },
  },
  setup() {
    const router = useRouter();

    const changeLocation = (location: string) => {
      if (!location) return;
      return router.push(location);
    };

    return {
      changeLocation,
    };
  },
});
