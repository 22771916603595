import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { class: "my-6 lg:my-8 container px-6 py-4 bg-gray-50 shadow-lg flex flex-col md:flex-row items-start md:items-center pb-4 border border-gray-300 max-w-full" }
const _hoisted_2 = {
  key: 0,
  class: "text-2xl font-bold leading-tight text-gray-800 dark:text-gray-100"
}
const _hoisted_3 = { key: 1 }
const _hoisted_4 = {
  key: 1,
  class: "text-lg leading-tight text-gray-600 dark:text-gray-100 mt-3"
}
const _hoisted_5 = {
  "aria-label": "current Status",
  class: "flex flex-col md:flex-row items-start md:items-center text-gray-600 dark:text-gray-400 text-sm mt-3"
}
const _hoisted_6 = { class: "flex flex-row" }
const _hoisted_7 = {
  key: 0,
  class: "mr-1"
}
const _hoisted_8 = ["onClick"]
const _hoisted_9 = {
  key: 1,
  class: "mx-2"
}
const _hoisted_10 = { class: "mt-6 md:mt-0 ml-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      (_ctx.heading)
        ? (_openBlock(), _createElementBlock("h4", _hoisted_2, [
            (_ctx.heading.icon)
              ? (_openBlock(), _createElementBlock("i", {
                  key: 0,
                  class: _normalizeClass([_ctx.heading.icon, "mr-3"])
                }, null, 2))
              : _createCommentVNode("", true),
            (_ctx.heading.text)
              ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.heading.text), 1))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true),
      (_ctx.subheading)
        ? (_openBlock(), _createElementBlock("h6", _hoisted_4, _toDisplayString(_ctx.subheading), 1))
        : _createCommentVNode("", true),
      _createElementVNode("ul", _hoisted_5, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.breadcrumbs, (value, index) => {
          return (_openBlock(), _createElementBlock("li", {
            class: "flex items-center",
            key: index
          }, [
            _createElementVNode("ul", _hoisted_6, [
              (value.icon)
                ? (_openBlock(), _createElementBlock("li", _hoisted_7, [
                    _createElementVNode("i", {
                      class: _normalizeClass([ value.to ?? 'text-blue-600 cursor-pointer' , value.icon])
                    }, null, 2)
                  ]))
                : _createCommentVNode("", true),
              _createElementVNode("li", {
                class: _normalizeClass(
                value.to ? 'text-blue-600 cursor-pointer font-semibold' : ''
              ),
                onClick: ($event: any) => (_ctx.changeLocation(value.to))
              }, _toDisplayString(value.text), 11, _hoisted_8),
              (index !== _ctx.breadcrumbs.length - 1)
                ? (_openBlock(), _createElementBlock("li", _hoisted_9, _toDisplayString(_ctx.separator), 1))
                : _createCommentVNode("", true)
            ])
          ]))
        }), 128))
      ])
    ]),
    _createElementVNode("div", _hoisted_10, [
      _renderSlot(_ctx.$slots, "buttons")
    ])
  ]))
}